import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import noteContext from './NoteContext';
import API from "./API";

function TeacherAsignReport() {
    const [data, setData] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [classes, setClasses] = useState([]);
    const [homeworks, sethomeworks] = useState([]);

    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedhomework, setSelectedhomework] = useState('');

    const [loadingbar, setHideLoading] = useState(false);
    const [assignedHomeworkv1, setAssignedHomeworkv1] = useState([]);
    const [selectedHomeworkIds, setSelectedHomeworkIds] = useState([]);

    const navigate = useNavigate();
    const a = useContext(noteContext);
    let token = a.token;

    const fetch_teachers_list = async () => {
        try {
            const res = await API.get("master/teachers_list", {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setData(res.data.teachers);
            setHideLoading(true);
        } catch (error) {
            console.error("Error fetching teachers list:", error);
        }
    };

    const fetch_classes = async (teacherId) => {
        try {
            const res = await API.get(`master/classes_list/${teacherId}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setClasses(res.data);
        } catch (error) {
            console.error("Error fetching classes list:", error);
        }
    };

    const fetch_subjects = async (classId) => {
        try {
            const res = await API.get(`master/Subjects/${classId}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setSubjects(res.data);
        } catch (error) {
            console.error("Error fetching subjects list:", error);
        }
    };

    const fetch_homeworks = async (teacherId, classId, subjectId, status) => {
        try {
            const res = await API.get(`master/hw/${classId}/${subjectId}?status=${status}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            sethomeworks(res.data.homeworks);
        } catch (error) {
            console.error("Error fetching homeworks list:", error);
        }
    };

    const fetchAssignedHomework = async (subject, status) => {
        try {


            alert(89);
            const res = await API.get(`master/hw/assign_homework_to_teacher/${selectedTeacher}/${selectedClass}/${subject}/${status}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setAssignedHomeworkv1(res.data.data);
        } catch (error) {
            console.error("Error fetching assigned homework:", error);
        }
    };

    const markHomeworkComplete = async (homework) => {
        try {
            const res = await API.post(`master/hw/update_assign_homework/${homework.id}`, {}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (!res.data.error) {
                fetchAssignedHomework(selectedSubject, selectedStatus);
            }
        } catch (error) {
            console.error("Error marking homework as complete:", error);
        }
    };

    const handleCheckboxChange = (homeworkId) => {
        setSelectedHomeworkIds((prevState) =>
            prevState.includes(homeworkId)
                ? prevState.filter((id) => id !== homeworkId)
                : [...prevState, homeworkId]
        );
    };

    const handlePrint = () => {
        const selectedHomeworks = assignedHomeworkv1.filter(homework =>
            selectedHomeworkIds.includes(homework.id)
        );
        const printContent = selectedHomeworks.map(homework => `
<div>

<p>Class ID: ${homework.class_id}</p>
<p>Subject ID: ${homework.subject_id}</p>
<p>Homework ID: ${homework.hw.homework}</p>

<p>Students:</p>
<ul>
${homework.students.map(student => `<li>${student.name}</li>`).join('')}
</ul>
<hr />
</div>
`).join('');

        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Print Report</title></head><body>');
        printWindow.document.write(printContent);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    useEffect(() => {
        if (a.login_status === null)
            navigate("/login")
        else {
            fetch_teachers_list();
        }
    }, []);

    return (
        <div>
            <div className="d-flex">
                <div>
                    <Select
                        value={selectedTeacher}
                        onChange={(e) => {
                            setSelectedTeacher(e.target.value);
                            fetch_classes(e.target.value);
                        }}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Select Teacher</MenuItem>
                        {data.length > 0 ? (
                            data.map((teacher) => (
                                <MenuItem key={teacher.id} value={teacher.id}>
                               {teacher.id}  -   {teacher.email}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value="" disabled>No Teachers Available</MenuItem>
                        )}
                    </Select>
                </div>

                <div>
                    {selectedTeacher && (
                        <div>
                            <Select
                                value={selectedClass}
                                onChange={(e) => {
                                    setSelectedClass(e.target.value);
                                    fetch_subjects(e.target.value);
                                }}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Class</MenuItem>
                                {classes.map((cls) => (
                                    <MenuItem key={cls} value={cls}>
                                        {cls}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}
                </div>

                <div>
                    {selectedClass && (
                        <div>
                            <Select
                                value={selectedSubject}
                                onChange={(e) => {
                                    setSelectedSubject(e.target.value);
                                    fetchAssignedHomework(e.target.value, selectedStatus);
                                }}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Subject</MenuItem>
                                {subjects.map((subject) => (
                                    <MenuItem key={subject} value={subject}>
                                        {subject}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}
                </div>

                <div>
                    {selectedSubject && (
                        <div>
                            <Select
                                value={selectedStatus}
                                onChange={(e) => {
                                    setSelectedStatus(e.target.value);
                                    fetchAssignedHomework(selectedSubject, e.target.value);
                                }}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Status</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                            </Select>
                        </div>
                    )}
                </div>
            </div>

            <div>
                <hr />
                {assignedHomeworkv1.length > 0 && (
                    <div>
                        <h3>Assigned Homework</h3>

                        <table className="table">
                            <tbody>
                                {assignedHomeworkv1.map((homework, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedHomeworkIds.includes(homework.id)}
                                                onChange={() => handleCheckboxChange(homework.id)}
                                            />
                                        </td>
                                        <td>
                                            Class: {homework.class_id}
                                            <br />
                                            Subject: {homework.subject_id}
                                            <br />
                                            Homework: {homework.hw.homework}
                                        </td>
                                        <td>
                                            <p>Students:</p>
                                            <ul>
                                                {homework.students.map((student) => (
                                                    <li key={student.id}>{student.name}</li>
                                                ))}
                                            </ul>
                                        </td>
                                        <td>
                                            Homework Status: {homework.status}
                                            <br />
                                            <button onClick={() => markHomeworkComplete(homework)}>
                                                Mark Complete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <button onClick={handlePrint}>Print Report</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TeacherAsignReport; 