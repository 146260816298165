
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import noteContext from './NoteContext';
import API from "./API";

function TeacherAsignReportv2() {
const [selectedTeacher, setSelectedTeacher] = useState('');
const [selectedClass, setSelectedClass] = useState('');
const [selectedSubject, setSelectedSubject] = useState('');
const [selectedStatus, setSelectedStatus] = useState('');
const [assignedHomework, setAssignedHomework] = useState([]);
const [teachers, setTeachers] = useState([]);
const [classes, setClasses] = useState([1, 2, 3, 4, 5]);
const [subjects, setSubjects] = useState(["Hindi", "Math", "English", "Social science", "Sanskrit", "General Awareness"]);
const [loadingbar, setHideLoading] = useState(false);
const [selectedHomeworkIds, setSelectedHomeworkIds] = useState([]);


const [assignedHomeworkv1, setAssignedHomeworkv1] = useState([]);



const navigate = useNavigate();
const { token, login_status } = useContext(noteContext);

useEffect(() => {
if (login_status === null) {
navigate("/login");
} else {
fetchTeachersList();
}
}, [login_status, navigate]);

const fetchTeachersList = async () => {
try {
const res = await API.get("master/teachers_list", {
headers: {
Accept: 'application/json',
Authorization: 'Bearer ' + token
}
});
console.log("Teachers List Response:", res.data);
setTeachers(res.data.teachers);
} catch (error) {
console.error("Error fetching teachers list:", error);
}
};

const markHomeworkComplete = async (homework) => {
try {
const newStatus = homework.status === 'Completed' ? 'Pending' : 'Completed';
const res = await API.post(`master/hw/update_assign_homework/${homework.id}`, { status: newStatus }, {
headers: {
Accept: 'application/json',
Authorization: 'Bearer ' + token
}
});
if (!res.data.error) {
FetchDataV2();
}
} catch (error) {
console.error("Error marking homework as complete:", error);
}
};

const FetchDataV2 = async () => {
try {
const endpoint = `qr/hw/assign_hw`;
const res = await API.post(endpoint, {
teacher_id: selectedTeacher,
class_id: selectedClass,
subject_id: selectedSubject,
status: selectedStatus
}, {
headers: {
Accept: 'application/json',
Authorization: 'Bearer ' + token
}
});
console.log("Assigned Homework Response:", res.data);
if (res.data && res.data.data) {
setAssignedHomework(res.data.data);
setAssignedHomeworkv1(res.data.data); // Update assignedHomeworkv1
} else {
console.error("Assigned homework data is missing or undefined");
setAssignedHomework([]);
setAssignedHomeworkv1([]); // Clear assignedHomeworkv1
}
} catch (error) {
console.error("Error fetching assigned homework:", error);
setAssignedHomework([]);
setAssignedHomeworkv1([]); // Clear assignedHomeworkv1
}
};

const handleCheckboxChange = (homeworkId) => {
setSelectedHomeworkIds((prevState) =>
prevState.includes(homeworkId)
? prevState.filter((id) => id !== homeworkId)
: [...prevState, homeworkId]
);
};

const handlePrint = () => {
const selectedHomeworks = assignedHomeworkv1.filter(homework =>
selectedHomeworkIds.includes(homework.id)
);
const printContent = selectedHomeworks.map(homework => `
<div>
<p>Class ID: ${homework.class_id}</p>
<p>Subject ID: ${homework.subject_id}</p>
<p>Homework ID: ${homework.hw.homework}</p>
<p>Students:</p>
<ul>
${homework.students.map(student => `<li>${student.name}</li>`).join('')}
</ul>
<hr />
</div>
`).join('');

const printWindow = window.open('', '', 'height=600,width=800');
printWindow.document.write('<html><head><title>Print Report</title></head><body>');
printWindow.document.write(printContent);
printWindow.document.write('</body></html>');
printWindow.document.close();
printWindow.print();
};

const handleSubmit = async (e) => {
e.preventDefault();
FetchDataV2();
};

return (
<div className="container mt-4">
<form onSubmit={handleSubmit}>
<div className="mb-3">
<select
className="form-select"
value={selectedTeacher}
onChange={(e) => {
setSelectedTeacher(e.target.value);
}}
>
<option value="" disabled>Select Teacher</option>
{teachers.length > 0 ? (
teachers.map((teacher) => (
<option key={teacher.id} value={teacher.id}>
{teacher.email}
</option>
))
) : (
<option value="" disabled>No Teachers Available</option>
)}
</select>
</div>
{selectedTeacher && (
<div className="mb-3">
<select
className="form-select"
value={selectedClass}
onChange={(e) => {
setSelectedClass(e.target.value);
}}
>
<option value="" disabled>Select Class</option>
{classes.map((cls) => (
<option key={cls} value={cls}>
{cls}
</option>
))}
</select>
</div>
)}
{selectedClass && (
<div className="mb-3">
<select
className="form-select"
value={selectedSubject}
onChange={(e) => setSelectedSubject(e.target.value)}
>
<option value="" disabled>Select Subject</option>
{subjects.map((subject) => (
<option key={subject} value={subject}>
{subject}
</option>
))}
</select>
</div>
)}
{selectedSubject && (
<div className="mb-3">
<select
className="form-select"
value={selectedStatus}
onChange={(e) => setSelectedStatus(e.target.value)}
>
<option value="">Select Status</option>
<option value="completed">Completed</option>
<option value="pending">Pending</option>
</select>
</div>
)}
<button type="submit" className="btn btn-primary">Submit</button>
</form>

<div>
<hr />
{assignedHomework.length > 0 ? (
<div>
<h3>Assigned Homework</h3>
<table className="table">
<tbody>
{assignedHomework.map((homework, index) => (
<tr key={index}>
<td>
<input
type="checkbox"
checked={selectedHomeworkIds.includes(homework.id)}
onChange={() => handleCheckboxChange(homework.id)}
/>
</td>
<td>
Class: {homework.class_id}
<br />
Subject: {homework.subject_id}
<br />
Homework: {homework.hw.homework}
</td>
<td>
<p>Students:</p>
<ul>
{homework.students.map(student => (
<li key={student.sr}>{student.name}</li>
))}
</ul>
</td>
<td>
Homework Status: {homework.status}
<br />
<button onClick={() => markHomeworkComplete(homework)}>
{homework.status === 'Completed' ? 'Mark Pending' : 'Mark Complete'}
</button>
</td>
</tr>
))}
</tbody>
</table>
<button onClick={handlePrint}>Print Report</button>
</div>
) : (
<p>No assigned homework found.</p>
)}
</div>
</div>
);
}

export default TeacherAsignReportv2;
